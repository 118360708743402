import { useSelector } from 'react-redux';
import styles from './brandsGallery.module.scss';
import BrandCard from '@/components/molecules/BrandCard';

const BrandsGallery = () => {
  const categories = useSelector((state) => state.navBar.categories);
  return (
    <div className={styles.brandsContainer}>
      {categories?.map((c) => (
        <BrandCard
          key={c.name}
          store={c.name}
          text={c.description}
          href={`/${c.slug}`}
          image={c.image.url}
        />
      ))}
    </div>
  );
};

export default BrandsGallery;
